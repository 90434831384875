import React from "react";
import "../../App.css";
import Contact from '../Contact';

function ContactMe(){
    
 return(
  <>
  <Contact/>
  </>
 )}




export default ContactMe;